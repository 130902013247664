exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-tutorials-app-architecture-js": () => import("./../../../src/pages/tutorials/app-architecture.js" /* webpackChunkName: "component---src-pages-tutorials-app-architecture-js" */),
  "component---src-pages-tutorials-background-planets-js": () => import("./../../../src/pages/tutorials/background-planets.js" /* webpackChunkName: "component---src-pages-tutorials-background-planets-js" */),
  "component---src-pages-tutorials-background-stars-js": () => import("./../../../src/pages/tutorials/background-stars.js" /* webpackChunkName: "component---src-pages-tutorials-background-stars-js" */),
  "component---src-pages-tutorials-changelog-js": () => import("./../../../src/pages/tutorials/changelog.js" /* webpackChunkName: "component---src-pages-tutorials-changelog-js" */),
  "component---src-pages-tutorials-dash-rive-animation-js": () => import("./../../../src/pages/tutorials/dash-rive-animation.js" /* webpackChunkName: "component---src-pages-tutorials-dash-rive-animation-js" */),
  "component---src-pages-tutorials-haptic-feedback-js": () => import("./../../../src/pages/tutorials/haptic-feedback.js" /* webpackChunkName: "component---src-pages-tutorials-haptic-feedback-js" */),
  "component---src-pages-tutorials-introduction-js": () => import("./../../../src/pages/tutorials/introduction.js" /* webpackChunkName: "component---src-pages-tutorials-introduction-js" */),
  "component---src-pages-tutorials-local-storage-js": () => import("./../../../src/pages/tutorials/local-storage.js" /* webpackChunkName: "component---src-pages-tutorials-local-storage-js" */),
  "component---src-pages-tutorials-multiplatform-js": () => import("./../../../src/pages/tutorials/multiplatform.js" /* webpackChunkName: "component---src-pages-tutorials-multiplatform-js" */),
  "component---src-pages-tutorials-performance-js": () => import("./../../../src/pages/tutorials/performance.js" /* webpackChunkName: "component---src-pages-tutorials-performance-js" */),
  "component---src-pages-tutorials-phrase-bubbles-js": () => import("./../../../src/pages/tutorials/phrase-bubbles.js" /* webpackChunkName: "component---src-pages-tutorials-phrase-bubbles-js" */),
  "component---src-pages-tutorials-puzzle-logic-js": () => import("./../../../src/pages/tutorials/puzzle-logic.js" /* webpackChunkName: "component---src-pages-tutorials-puzzle-logic-js" */),
  "component---src-pages-tutorials-responsiveness-js": () => import("./../../../src/pages/tutorials/responsiveness.js" /* webpackChunkName: "component---src-pages-tutorials-responsiveness-js" */),
  "component---src-pages-tutorials-rive-tile-animations-js": () => import("./../../../src/pages/tutorials/rive-tile-animations.js" /* webpackChunkName: "component---src-pages-tutorials-rive-tile-animations-js" */),
  "component---src-pages-tutorials-stopwatch-js": () => import("./../../../src/pages/tutorials/stopwatch.js" /* webpackChunkName: "component---src-pages-tutorials-stopwatch-js" */),
  "component---src-pages-tutorials-testing-js": () => import("./../../../src/pages/tutorials/testing.js" /* webpackChunkName: "component---src-pages-tutorials-testing-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

